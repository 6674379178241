<template>
  <div 
  class="w-full mt-4 bg-slate-900 text-white font-sans shadow-[0_0_30px_10px_rgba(0,0,0,0.1)] overflow-hidden rounded-lg ring-media-focus data-[focus]:ring-4" 
  style="position: relative; padding-top: 56.25%;"
  >
    <div class="absolute top-0 left-0 w-full h-full">
      <div v-if="!isVideoLoaded" class="w-full h-full" @click="loadVideo">
        <ImageResponsive
        v-if="thumbnail"
        classes="w-full h-full object-cover"
        class="w-full h-full object-cover"
        :width="1175"
        :height="661"
        :src="thumbnail"
        alt="Meet Elker video thumbnail"
        :xs="11"
        :sm="11"
        :md="11"
        :lg="11"
        :xl="11"
        />
        <button
        aria-label="Play Meet Elker video - discover how Elker can help your organisation"
        class="absolute shadow-lg cursor-pointer top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[70px] h-[70px] rounded-full bg-[#6366F1] hover:bg-[#4F46E5] transition-colors duration-200 flex items-center justify-center"
        >
          <svg 
          xmlns="http://www.w3.org/2000/svg" 
          viewBox="0 0 24 24" 
          class="w-10 h-10 text-white fill-current"
          style="margin-left: 2px;"
          >
            <path d="M8 5v14l11-7z" />
          </svg>
        </button>
      </div>

      <div 
      v-show="isVideoLoaded" 
      class="relative w-full h-full"
      >
        <video
        ref="videoElement"
        class="w-full h-full"
        controls
        preload="none"
        playsinline
        crossorigin="anonymous"
        @play="isPlaying = true"
        @pause="isPlaying = false"
        @ended="isPlaying = false"
        >
          <source
          v-for="source in videoSources"
          :key="source.quality"
          :src="source.url"
          :type="'video/mp4'"
          />
          <track
          v-if="captions"
          kind="captions"
          :src="captions"
          srclang="en"
          label="English"
          default
          />
        </video>
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  thumbnail: {
    type: String,
    required: true
  },
  videoSources: {
    type: Array,
    required: true,
    validator: (value) => {
      return value.every(source => 
        source.hasOwnProperty('quality') && source.hasOwnProperty('url')
      )
    }
  },
  captions: {
    type: String,
    default: null
  }
})

const videoElement = ref(null)
const isVideoLoaded = ref(false)
const currentVideoSource = ref(null)
const isPlaying = ref(false)

const sortedSources = computed(() => {
  return [...props.videoSources].sort((a, b) => {
    const qualityA = parseInt(a.quality)
    const qualityB = parseInt(b.quality)
    return qualityB - qualityA
  })
})

const getAppropriateQuality = (width) => {
  if (width <= 360) return '480p'
  if (width <= 1024) return '720p'
  return '1080p'
}

const findBestMatchingSource = (targetQuality) => {
  const targetRes = parseInt(targetQuality)
  
  const exactMatch = sortedSources.value.find(source => 
    source.quality === targetQuality
  )
  if (exactMatch) return exactMatch

  const lowerQuality = sortedSources.value
    .filter(source => parseInt(source.quality) <= targetRes)
    .sort((a, b) => parseInt(b.quality) - parseInt(a.quality))[0]

  return lowerQuality || sortedSources.value[0]
}

const updateVideoQuality = () => {
  const width = window.innerWidth
  const appropriateQuality = getAppropriateQuality(width)
  const newSource = findBestMatchingSource(appropriateQuality)
  
  if (newSource && (!currentVideoSource.value || 
      currentVideoSource.value.quality !== newSource.quality)) {
    const currentTime = videoElement.value?.currentTime || 0
    const wasPlaying = isPlaying.value
    
    currentVideoSource.value = newSource
    
    if (isVideoLoaded.value && videoElement.value) {
      nextTick(() => {
        videoElement.value.currentTime = currentTime
        if (wasPlaying) videoElement.value.play()
      })
    }
  }
}

let resizeObserver
onMounted(() => {
  if (process.client) {
    updateVideoQuality()    
  }
})

// Methods
const loadVideo = () => {
  isVideoLoaded.value = true
  nextTick(() => {
    if (videoElement.value) {
      videoElement.value.play()
    }
  })
}

// Clean up
onUnmounted(() => {
  if (videoElement.value) {
    videoElement.value.pause()
    videoElement.value.src = ''
    videoElement.value.load()
  }
})

useSchemaOrg([
  defineVideo({
    name: 'Meet Elker - product tour',
    uploadDate: "2024-09-13T04:54:22.3890000+00:00",
    duration: "PT1M53S",
    description: "Elker is an anonymous reporting platform for people to speak up about workplace misconduct, harassment, and discrimination. Discover how Elker can help your organisation.",
    thumbnailUrl: props.thumbnail,
    contentUrl: props.videoSources[0]?.url,
  })
])

</script>